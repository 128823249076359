<template>
  <div class="base-icon">
    <img
      v-if="!active"
      class="base-icon__img"
      :src="getIconPath"
      alt="icon"
      @click="$emit('click')"
      :style="screenSizeDependency ? { width: `${width}px`, height: `${height}px` } : {}"
    />
    <img
      v-else
      class="base-icon__img"
      :src="getIconPath"
      alt="icon"
      @click="$emit('click')"
      :style="screenSizeDependency ? { width: `${width}px`, height: `${height}px` } : {}"
    />
  </div>
</template>
<script lang="js">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "BaseIcon",
  props: {
    path: {
      type: String,
      required: true,
    },
    pathActive: {
      type: String,
      default: '',
    },
    screenSizeDependency: {
      type: Boolean,
    },
    width: {
      type: String | Number,
    },
    height: {
      type: String | Number,
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const getIconPath = computed(() => {
      return require(`@/assets/icons/${props.path}`)
    })

    return {
      getIconPath,
    }
  }
});
</script>
<style lang="scss" scoped>
.base-icon {
  display: flex;
  flex-direction: column;

  &__img {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 1440px) {
  .base-icon {
    &__img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
