export const navLinks = [
  {
    to: "/today",
    title: "Сегодня",
    name: "today",
    roles: ["user", "admin"],
    uid: 0,
    showFlag: false,
    paidFeature: false,
    startIcon: "navBar/Coffee.svg",
  },
  {
    to: "/schedule",
    title: "Расписание",
    name: "schedule",
    roles: ["user", "admin"],
    uid: 0,
    showFlag: false,
    paidFeature: false,
    startIcon: "navBar/CalendarBlank.svg",
  },
  {
    to: "/personal",
    title: "Мои смены",
    name: "personal",
    roles: ["user", "admin"],
    uid: 0,
    showFlag: false,
    paidFeature: false,
    startIcon: "navBar/MyWork.svg",
  },
  {
    to: "/freeturns",
    title: "Свободные",
    name: "freeturns",
    roles: ["user", "admin"],
    uid: 0,
    showFlag: false,
    paidFeature: false,
    startIcon: "navBar/HandWaving.svg",
  },
  {
    // to: "/othersusr",
    title: "Другое",
    name: "othersusr",
    roles: ["user", "admin"],
    uid: 0,
    showFlag: true,
    paidFeature: false,
    startIcon: "navBar/Other.svg",
    dropdownMenu: [
      {
        to: "/peoplelist",
        title: "Люди",
        name: "peoplelist",
        roles: ["user", "admin"],
        uid: 0,
        showFlag: true,
        paidFeature: false,
        startIcon: "navBar/People.svg",
      },
      {
        to: "/moneypersonal",
        title: "Мои финансы",
        name: "moneypersonal",
        roles: ["user", "admin"],
        uid: 0,
        showFlag: true,
        paidFeature: false,
        startIcon: "navBar/Wallet.svg",
      },
      {
        to: "/eventcalendar",
        title: "Календарь событий",
        name: "eventCalendar",
        roles: ["user", "admin"],
        uid: 0,
        showFlag: true,
        paidFeature: false,
        startIcon: "navBar/CalendarX.svg",
      },
    ],
  },
  {
    to: "/wiki",
    title: "Wiki",
    name: "wiki",
    roles: ["user", "admin"],
    uid: 0,
    showFlag: false,
    paidFeature: false,
    startIcon: "navBar/FileText.svg",
  },
  {
    title: "Администратор",
    name: "adminRoom",
    roles: ["admin"],
    uid: 0,
    showFlag: true,
    paidFeature: false,
    startIcon: "navBar/Home.svg",
    dropdownMenu: [
      {
        to: "/reference",
        title: "Справочники",
        name: "directory",
        roles: ["admin"],
        uid: 0,
        showFlag: false,
        paidFeature: false,
        startIcon: "navBar/Directories.svg",
      },
      {
        to: "/reports",
        title: "Отчёты",
        name: "reports",
        roles: ["admin"],
        uid: 0,
        showFlag: false,
        paidFeature: false,
        startIcon: "navBar/Reports.svg",
      },
      {
        to: "/checkMenu",
        title: "Чеки",
        name: "salesin",
        roles: ["admin"],
        uid: 0,
        showFlag: true,
        paidFeature: true,
        startIcon: "navBar/Salein.svg",
      },
      {
        to: "/othersadm",
        title: "Буфер",
        name: "buffer",
        roles: ["admin"],
        uid: 0,
        showFlag: true,
        paidFeature: true,
        startIcon: "navBar/CrownSimple.svg",
      },
    ],
  },
  {
    title: "Локал. Админ",
    name: "pointAdminRoom",
    roles: ["pointadmin", "admin"],
    uid: 0,
    showFlag: true,
    paidFeature: true,
    startIcon: "navBar/Flag.svg",
    dropdownMenu: [
      {
        to: "/chesstabledraft",
        title: "Черновик",
        name: "draft",
        roles: ["pointadmin", "admin"],
        uid: 0,
        showFlag: false,
        paidFeature: true,
        startIcon: "navBar/Draft.svg",
      },
      {
        to: "/adminsWiki",
        title: "Admin’s Wiki",
        name: "AdminsWiki",
        roles: ["admin"],
        uid: 0,
        showFlag: false,
        paidFeature: false,
        startIcon: "navBar/FileText.svg",
      },
    ],
  },
  {
    title: "HR-Менеджер",
    name: "hrRoom",
    roles: ["hradmin", "admin"],
    uid: 0,
    showFlag: true,
    paidFeature: true,
    startIcon: "navBar/Handshake.svg",
    dropdownMenu: [
      {
        to: "/peoplelist",
        title: "Персонал",
        name: "peoplelist",
        roles: ["hradmin", "admin"],
        uid: 0,
        showFlag: false,
        paidFeature: true,
        startIcon: "navBar/MyWork.svg",
      },
      {
        to: "/bariskahistory",
        title: "Путь бариста",
        name: "bariskahistory",
        roles: ["hradmin", "admin"],
        uid: 0,
        showFlag: false,
        paidFeature: true,
        startIcon: "navBar/Human.svg",
      },
      {
        to: "/hrWiki",
        title: "Hr’s Wiki",
        name: "HrWiki",
        roles: ["admin"],
        uid: 0,
        showFlag: false,
        paidFeature: false,
        startIcon: "navBar/FileText.svg",
      },
    ],
  },
  // {
  //   to: "/peoplelist",
  //   title: "Люди",
  //   roles: ["user"],
  //   showFlag: false,
  // },
  {
    to: "/mybusiness",
    title: "God mode",
    name: "godMod",
    roles: ["clientadmin"],
    uid: -1,
    showFlag: false,
    startIcon: "navBar/godMode.svg",
  },
  {
    to: "/logout",
    title: "Выход",
    name: "logout",
    roles: ["user", "admin", "clientadmin"],
    uid: -1,
    showFlag: false,
    startIcon: "navBar/Door.svg",
  },
];
