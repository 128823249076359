import Vue from "vue";
import Vuex from "vuex";
import { state } from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";

Vue.use(Vuex);

export const options = {
  state,
  mutations,
  actions,
};

export default new Vuex.Store(options);
