<template>
  <div class="full-screen">
    <div class="full-screen__wrapper" @click="$emit('hideFullScreen')">
      <div v-if="showFullScreen" class="full-screen__overlay"></div>
      <img v-if="showFullScreen" class="full-screen__img" :src="imgPath" />
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FullScreen",
  props: {
    showFullScreen: {
      type: Boolean,
      required: true,
    },
    imgPath: {
      type: String,
      required: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  &__close {
    position: fixed;
    right: 15px;
    top: 15px;
  }
  &__img {
    max-width: 30%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 768px) {
  .full-screen {
    &__img {
      max-width: 80%;
    }
  }
}
</style>
