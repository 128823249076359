export const inputProps = {
  value: { type: String | Date },
  type: { type: String, default: "text" },
  label: { type: String },
  name: { type: String },
  placeholder: { type: String, default: "" },
  appendIconPath: { type: String },
  disabled: { type: Boolean },
  checked: { type: Boolean },
  maxLength: { type: Number, default: -1 },
  required: { type: Boolean, default: false },
};

export const selectProps = {
  ...inputProps,
  optionList: { type: Array, require: true },
  multiple: { type: Boolean },
};

export const buttonProps = {
  startIconPath: { type: String, default: "" },
  to: { type: String, default: "" },
  isHref: { type: Boolean, default: false },
  buttonMenu: { type: Boolean, default: false },
  dropdownMenu: { type: Array, default: () => [] },
  iconSize: { type: String, default: "32" },
  rounded: { type: Boolean, default: true },
  contentAfter: { type: Boolean, default: false },
  // sizes
  extraSmall: { type: Boolean },
  small: { type: Boolean },
  medium: { type: Boolean },
  large: { type: Boolean },
  extraLarge: { type: Boolean },
  // colors
  primary: { type: Boolean },
  secondary: { type: Boolean },
  tertiary: { type: Boolean },
  transparent: { type: Boolean },
  negative: { type: Boolean },
  disabled: { type: Boolean },
};

export const chipProps = {
  value: { type: String },
  // sizes
  s: { type: Boolean },
  m: { type: Boolean },
  l: { type: Boolean },
  xl: { type: Boolean },
  xxl: { type: Boolean },
  // colors
  purple: { type: Boolean },
  yellow: { type: Boolean },
  red: { type: Boolean },
  blue: { type: Boolean },
  green: { type: Boolean },
  // icons
  prependIconPath: { type: String },
  appendIconPath: { type: String },
};
