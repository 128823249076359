class lS {
  set(token) {
    localStorage.setItem("token", token);
  }

  clear() {
    localStorage.removeItem("token");
  }

  saveObject(key, str) {
    localStorage.setItem(key, JSON.stringify(str));
  }

  getObject(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  getObjectProp(key, prop) {
    const obj = JSON.parse(localStorage.getItem(key));
    if (obj && obj[prop]) return obj[prop];
  }
}

export { lS };
