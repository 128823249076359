var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-button-new",class:{
    '__extra-small': _vm.extraSmall,
    __small: _vm.small,
    __medium: _vm.medium,
    __large: _vm.large,
    '__extra-large': _vm.extraLarge,
    __primary: _vm.primary,
    __secondary: _vm.secondary,
    __tertiary: _vm.tertiary,
    __transparent: _vm.transparent,
    __negative: _vm.negative,
    __disabled: _vm.disabled,
  },on:{"click":function($event){return _vm.$emit('click')},"mouseleave":function($event){return _vm.$emit('mouseleave')},"mouseover":function($event){return _vm.$emit('mouseover')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }