var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(_vm.to ? 'router-link' : _vm.isHref ? 'a' : 'button',{tag:"button",staticClass:"base-button",class:{
      '__button-menu': _vm.buttonMenu,
      'router-link-active': _vm.dropdownMenu.length > 0 && _vm.isDropdownOpen,
      'dropdown-button': _vm.dropdownMenu.length > 0,
      rounded: _vm.rounded,
      'content-after': _vm.contentAfter,
    },attrs:{"to":_vm.to,"disabled":_vm.disabled},on:{"click":_vm.openDropDownMenu}},[[(_vm.startIconPath)?_c('base-icon',{staticClass:"base-button__start-icon",attrs:{"path":_vm.startIconPath,"width":_vm.iconSize,"height":_vm.iconSize}}):_vm._e(),_vm._t("default"),_vm._t("label"),(_vm.dropdownMenu.length > 0)?_c('base-icon',{staticClass:"base-button__end-icon",class:{ __opened: _vm.isDropdownOpen },attrs:{"width":"24","height":"24","path":'navBar/CaretUp.svg'}}):_vm._e()]],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.dropdownMenu.length > 0 && _vm.isDropdownOpen)?_c('dropdown-menu',{attrs:{"is-dropdown-open":_vm.isDropdownOpen,"dropdown-menu":_vm.dropdownMenu.filter(item => item.showFlag),"button-menu":_vm.buttonMenu,"rounded":_vm.rounded},on:{"closeMobileMenu":function($event){return _vm.$emit('closeMobileMenu')}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }