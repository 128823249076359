import Vue from "vue";
import App from "@/App.vue";
import router from "@/router-vue/index.js";
import store from "./store";

Vue.config.productionTip = true;
Vue.config.devtools = true;
Vue.use(store);

new Vue({
  store,
  router,
  render: h => h(require("./App.vue").default),
}).$mount("#app");
